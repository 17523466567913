import React, { useContext, useEffect, useState } from 'react';
import { SelectWrapper, SelectData } from './SelectEditionStyles';
import { GlobalDispatchContext, GlobalStateContext } from '../../GlobalContextProvider';

const SelectEdition = () => {

    const dispatch = useContext(GlobalDispatchContext);
    const state = useContext(GlobalStateContext);
    const edition = state.filterEdition;
    const [value, setEdition] = useState('all')
    const handleChangeEdition = (e) => {
        const edition = e.currentTarget.value;
        dispatch({ type: 'CHANGE_FILTER_EDITION', payload: edition})
    }

    useEffect(() => {
        setEdition(edition)
    }, [ edition ])

    return (
        <SelectWrapper>
            <SelectData onChange = { handleChangeEdition } value = { value }>
                <option value="all" >filtruj po edycji</option>
                <option value = "all" >wszystkie</option>
                <option value="2024" >2024</option>
                <option value="2023" >2023</option>
                <option value="2022" >2022</option>
                <option value="2021" >2021</option>
                <option value="2020" >2020</option>
                <option value="2018" >2018</option>
                <option value="2017" >2017</option>
                <option value="2016" >2016</option>
                <option value="2015" >2015</option>
                <option value="2014" >2014</option>
                <option value="2013" >2013</option>
                
            </SelectData>
        </SelectWrapper>
    )
};

export default SelectEdition;
